<template>
  <face-template
    :facial-spot-locations="facialSpotLocations"
    :gender="gender"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import FaceTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-pigmentation/face/FaceTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

const { field, requiredField } = fieldBuilder;

const FIELDS = [field('gender'), requiredField('facialSpotLocations')];

export default {
  name: 'Face',
  components: {
    FaceTemplate
  },
  mixins: [makeStep(FIELDS)]
};
</script>
